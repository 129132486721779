import styled from "styled-components"

import Layout from "src/layout"

const Center = styled.div`
  padding-top: 20vh;
  text-align: center;
`

const NotFoundPage: GatsbyPage = () => (
  <Layout theme="dark" title="404">
    <Center>
      <h1>Erreur 404</h1>
      <p>Cette page n’existe pas.</p>
    </Center>
  </Layout>
)

export default NotFoundPage
